import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import dynamic from "next/dynamic";
import React, { FC, memo } from "react";
import { CookieConsentModalFormProps } from "./cookie-consent-modal-form";

const CookieConsentModalForm = dynamic<any>(
    () => import("./cookie-consent-modal-form").then((component) => component.CookieConsentModalForm),
    {
        ssr: false,
    },
);

const Component: FC<CookieConsentModalFormProps> = ({ setConsentData }) => {
    return <CookieConsentModalForm setConsentData={setConsentData} />;
};

const Wrapper: FC<CookieConsentModalFormProps> = ({ setConsentData }) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component setConsentData={setConsentData} /> : null;
};

export const CookieConsentFormContainer = memo(Wrapper);

CookieConsentFormContainer.displayName = "CookieConsentFormContainer";

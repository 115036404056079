import { config } from "@config/config";
import { useGtmScript } from "@uxf/analytics/gtm";
import { isServer } from "@uxf/core/utils/isServer";
import Head from "next/head";
import { memo } from "react";

export const Analytics = memo(() => {
    const gtmScript = useGtmScript(config.GTM_ID ?? "");

    return <Head>{config.GTM_ID && !isServer && <script dangerouslySetInnerHTML={{ __html: gtmScript }} />}</Head>;
});

Analytics.displayName = "Analytics";

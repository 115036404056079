import { useLocale } from "@translations/locale-context";
import { Button } from "@ui/components/button/button";
import { SectionLayout } from "@ui/components/section-layout/section-layout";
import { Trans, useTranslation } from "next-i18next";
import Link from "next/link";
import React, { memo } from "react";

interface Props {
    ctaTitle: string;
    items: { description: string; title: string; route?: string }[];
    sectionName: string;
    sectionTitle: string;
}

export const ServicesSection = memo<Props>(({ ctaTitle, items, sectionName, sectionTitle }) => {
    const { t } = useTranslation();
    const locale = useLocale();
    const isEn = locale === "en";

    return (
        <SectionLayout
            position="row"
            variant="violet"
            name={sectionName}
            title={sectionTitle}
            className="pb-20 pt-24 md:pb-28 md:pt-24 lg:pt-44"
        >
            <div className="space-y-11 divide-y divide-white divide-opacity-20">
                {items.map(({ description, title, route }, index) => (
                    <div
                        key={index}
                        className="ui-typo-body grid pt-11 sm:grid-cols-6 sm:grid-rows-[auto_1fr] sm:gap-x-6 xl:grid-cols-12 xl:grid-rows-1"
                    >
                        <h3 className="ui-typo-large mb-6 sm:col-span-6 sm:mb-10 xl:col-span-4 xl:mb-0">{t(title)}</h3>
                        <p className="mb-8 opacity-75 sm:col-span-4 sm:mb-0 xl:col-span-5">
                            <Trans i18nKey={description} />
                        </p>
                        <div className="sm:col-span-2 sm:text-right xl:col-span-3">
                            <Link
                                href={route ?? (isEn ? "/en/book-consultation" : "/sjednat-konzultaci")}
                                passHref
                                legacyBehavior
                            >
                                <Button variant="white" outlined>
                                    {ctaTitle}
                                </Button>
                            </Link>
                        </div>
                    </div>
                ))}
            </div>
        </SectionLayout>
    );
});

ServicesSection.displayName = "ServicesSection";

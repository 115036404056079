import { UxfCookiesConsentType } from "@shared/lib/cookie-consent/cookie-consent.types";
import { createCookieConsentData } from "@shared/lib/cookie-consent/create-cookie-consent-data";
import { setCookieConsent } from "@shared/lib/cookie-consent/set-cookie-consent";
import { openModal } from "@shared/services/modal-service";
import { useLocale } from "@translations/locale-context";
import { Button } from "@ui/components/button/button";
import { TextLink } from "@ui/components/text-link/text-link";
import { Trans, useTranslation } from "next-i18next";
import React, { FC, memo, useCallback } from "react";
import { CookieConsentFormContainer } from "./form/cookie-consent-modal-form-container";

export type CookieConsentBarProps = {
    onClose: () => void;
};

const Component: FC<CookieConsentBarProps> = ({ onClose }) => {
    const { t } = useTranslation();
    const locale = useLocale();

    const setConsentData = useCallback(
        (consentData: UxfCookiesConsentType) => () => {
            setCookieConsent(consentData);
        },
        [],
    );

    const onAcceptAll = useCallback(() => {
        setConsentData(createCookieConsentData(true))();
        onClose();
    }, [onClose, setConsentData]);

    const onDenyAll = useCallback(() => {
        setConsentData(createCookieConsentData(false))();
        onClose();
    }, [onClose, setConsentData]);

    return (
        <div className="fixed inset-0 z-50 h-screen w-screen bg-black/30">
            <div className="fixed bottom-0 bg-white px-6 py-8 md:bottom-6 md:right-8 md:max-w-[600px] md:rounded-2xl">
                <h2 className="ui-typo-medium mb-6 font-bold">{t("cookies.bar.title")}</h2>
                <p className="ui-typo-body mb-8">
                    {t("cookies.bar.subtitle")}{" "}
                    <Trans
                        i18nKey="cookies.bar.privacyPolicy"
                        components={{
                            a: (
                                <TextLink
                                    href={locale === "en" ? "/en/privacy-policy" : "/ochrana-osobnich-udaju"}
                                    variant="orange"
                                />
                            ),
                        }}
                    />
                </p>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:grid-rows-2 sm:space-y-0">
                    <Button className="sm:col-span-2" variant="orange" onClick={onAcceptAll} size="small">
                        {t("cookies.bar.acceptBtn")}
                    </Button>
                    <Button onClick={onDenyAll} outlined variant="violet" size="small">
                        {t("cookies.bar.denyBtn")}
                    </Button>
                    <Button
                        onClick={() => {
                            onClose();
                            openModal({
                                children: <CookieConsentFormContainer setConsentData={setConsentData} />,
                                disableCloseButton: true,
                                fullWidth: true,
                            });
                        }}
                        outlined
                        size="small"
                        variant="violet"
                    >
                        {t("cookies.bar.preferenceBtn")}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export const CookieConsentBar = memo(Component);

CookieConsentBar.displayName = "CookieConsentBar";

import { Icon } from "@ui/components/icon/icon";
import { TextLink } from "@ui/components/text-link/text-link";
import { useTranslation } from "next-i18next";
import React, { forwardRef } from "react";

interface NavbarButtonProps {
    href?: string;
    onClick?: () => void;
    title: string;
}

export const NavbarMobileButton = forwardRef<HTMLAnchorElement, NavbarButtonProps>(({ href, onClick, title }, ref) => {
    const { t } = useTranslation();

    return (
        <TextLink
            className="ui-typo-medium flex items-center justify-between text-white"
            href={href}
            onClick={onClick}
            role="listitem"
            ref={ref}
            variant="white"
        >
            <span>{t(title)}</span>
            <span className="sm:hidden">
                <Icon name="chevronRight" size={16} className="text-white/75" />
            </span>
        </TextLink>
    );
});

NavbarMobileButton.displayName = "NavbarMobileButton";

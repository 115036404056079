import { NAV_MENU } from "@config/nav-menu-config";
import { NavBarMobileMenu } from "@shared/components/navbar/navbar-mobile-menu";
import { NavBarSubMenu } from "@shared/components/navbar/navbar-submenu";
import { useLocale } from "@translations/locale-context";
import { Logo } from "@ui/components/logo/logo";
import { useButtonProps } from "@uxf/core/hooks/useButtonProps";
import { useOnMount } from "@uxf/core/hooks/useOnMount";
import { cx } from "@uxf/core/utils/cx";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { ButtonHTMLAttributes, Fragment, memo, useCallback, useState } from "react";
import { LanguageSelector } from "./language-selector";
import { NavbarButton } from "./navbar-button";

interface Props {
    variant?: "white" | "violet";
}

export const NavBar = memo<Props>(({ variant = "white" }) => {
    const locale = useLocale();
    const { t } = useTranslation();

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    const toggleMobileMenu = useCallback(() => setMobileMenuOpen((prev) => !prev), []);

    const navbarBurgerProps = useButtonProps<ButtonHTMLAttributes<HTMLButtonElement>>({
        className: cx(
            "flex h-14 w-14 flex-col items-center justify-center p-2 lg:hidden",
            variant === "white"
                ? "text-uxfViolet is-hoverable:text-uxfOrange"
                : "text-white is-hoverable:text-whiteHover",
        ),
        onClick: toggleMobileMenu,
        title: t("navbar.showNav"),
    });

    const { events } = useRouter();

    useOnMount(() => {
        const handleRouteChange = () => setMobileMenuOpen(false);
        events.on("routeChangeComplete", handleRouteChange);
        return () => events.off("routeChangeComplete", handleRouteChange);
    });

    return (
        <div className={variant === "white" ? "bg-white" : "bg-uxfViolet"}>
            <header className="container flex h-24 items-center justify-between px-6 md:px-0">
                <Logo variant={variant === "white" ? "colorViolet" : "colorWhite"} />
                <div className="item-center hidden justify-end lg:flex lg:gap-x-9 xl:gap-x-12" role="list">
                    {NAV_MENU.map(({ href, title, submenu }) => (
                        <Fragment key={title}>
                            {href ? (
                                <Link href={href[locale]} passHref legacyBehavior>
                                    <NavbarButton title={title} variant={variant === "white" ? "violet" : "white"} />
                                </Link>
                            ) : (
                                <NavBarSubMenu title={title} variant={variant} data={submenu} />
                            )}
                        </Fragment>
                    ))}
                    <LanguageSelector variant={variant === "white" ? "violet" : "orange"} />
                </div>
                <button {...navbarBurgerProps}>
                    <div className="mb-2 h-[4px] w-8 rounded-full bg-current" />
                    <div className="h-[4px] w-8 rounded-full bg-current" />
                </button>
                {mobileMenuOpen && <NavBarMobileMenu onClose={toggleMobileMenu} />}
            </header>
        </div>
    );
});

NavBar.displayName = "NavBar";

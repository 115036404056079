import { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef, useState } from "react";
import { ModalContent } from "./modal-content";

export interface ModalProps {
    children: ReactNode;
    disableCloseButton?: boolean;
    fullWidth?: boolean;
    onClose?: () => void;
}

export interface ModalRef {
    close: () => void;
    open: (modal: ModalProps) => void;
}

export const Modal = forwardRef<ModalRef>((_, ref) => {
    const [modal, setModal] = useState<ModalProps | null>();

    const open = useCallback((newModal: ModalProps) => setModal(newModal), []);

    const close = useCallback(() => setModal(null), []);

    const innerRef = useRef<ModalRef>({
        close,
        open,
    });

    useImperativeHandle(
        ref,
        () => ({
            close: innerRef.current.close,
            open: innerRef.current.open,
        }),
        [],
    );

    const onClose = useCallback(
        () =>
            setModal((prev) => {
                if (prev?.onClose) {
                    prev.onClose();
                }
                return null;
            }),
        [],
    );

    return modal ? (
        <ModalContent
            disableCloseButton={modal.disableCloseButton}
            fullWidth={modal.fullWidth}
            isOpen={!!modal}
            onClose={onClose}
        >
            {modal.children}
        </ModalContent>
    ) : null;
});

Modal.displayName = "Modal";

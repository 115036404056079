import { NavSubMenu } from "@config/nav-menu-config";
import { autoUpdate, flip, shift, size, useFloating } from "@floating-ui/react-dom";
import { Popover, Transition } from "@headlessui/react";
import { useLocale } from "@translations/locale-context";
import { Icon } from "@ui/components/icon/icon";
import { TextLink } from "@ui/components/text-link/text-link";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import React, { Fragment, memo } from "react";
import { NavbarButton } from "./navbar-button";

interface Props {
    data: NavSubMenu[];
    title: string;
    variant?: "white" | "violet";
}

export const NavBarSubMenu = memo<Props>(({ variant = "white", title, data }) => {
    const locale = useLocale();
    const isEn = locale === "en";
    const { t } = useTranslation();

    const float = useFloating({
        placement: "bottom-start",
        middleware: [
            flip(),
            shift({ padding: 16 }),
            size({
                apply({ elements, strategy, x, y }) {
                    Object.assign(elements.floating.style, {
                        left: x + "px",
                        position: strategy,
                        top: y + "px",
                    });
                },
            }),
        ],
        whileElementsMounted: (reference, floating, update) =>
            autoUpdate(reference, floating, update, {
                elementResize: typeof ResizeObserver !== "undefined",
            }),
    });

    return (
        <Popover className="relative">
            <Popover.Button
                as={NavbarButton}
                hasSubMenu
                ref={float.refs.setReference}
                title={title}
                variant={variant === "white" ? "violet" : "white"}
            />
            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel
                    as="div"
                    className={`absolute z-50 mt-12 w-[54.375rem] overflow-hidden rounded-3xl ${
                        variant === "white" ? "bg-white shadow-popover" : "border border-uxfMuted75 bg-uxfVioletDark"
                    }`}
                    ref={float.refs.setFloating}
                    style={{
                        position: float.strategy,
                        top: float.y,
                        left: float.x,
                    }}
                >
                    {({ close }) => (
                        <>
                            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                            <div className="grid grid-cols-2 gap-x-10 gap-y-8 p-8" onClick={() => close()}>
                                {data.map((section, index) => (
                                    <div key={index}>
                                        <div className="mb-1 flex items-center">
                                            <Link href={section.href[locale]} passHref legacyBehavior>
                                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/no-static-element-interactions */}
                                                <a
                                                    className={`mr-4 flex size-10 items-center justify-center rounded-lg ${
                                                        variant === "white"
                                                            ? "bg-uxfViolet text-white"
                                                            : "bg-white text-uxfVioletDark"
                                                    }`}
                                                >
                                                    <Icon name={section.icon} size={section.iconSize} />
                                                </a>
                                            </Link>
                                            <Link href={section.href[locale]} passHref legacyBehavior>
                                                <TextLink
                                                    className="ui-typo-h6 font-bold"
                                                    variant={variant === "white" ? "violet" : "white"}
                                                >
                                                    {t(section.title)}
                                                </TextLink>
                                            </Link>
                                        </div>
                                        <div className="ui-typo-body ml-14 flex flex-wrap">
                                            {section.items.map((link, i) => (
                                                <Link
                                                    key={`${index}-${i}`}
                                                    href={link.href[locale]}
                                                    passHref
                                                    legacyBehavior
                                                >
                                                    <TextLink
                                                        className="mt-2 [&:not(:last-child)]:mr-6"
                                                        variant={variant === "white" ? "muted" : "whiteMuted"}
                                                    >
                                                        {t(link.title)}
                                                    </TextLink>
                                                </Link>
                                            ))}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div
                                className={`ui-typo-body flex items-center justify-between px-8 py-4 ${
                                    variant === "white"
                                        ? "bg-uxfVioletLightest"
                                        : "border-t border-uxfMuted75 bg-uxfVioletDark text-white"
                                }`}
                            >
                                <p>{t("navbar.collaborate")}</p>
                                <Link href={isEn ? "/en/book-consultation" : "/sjednat-konzultaci"} legacyBehavior>
                                    <TextLink variant="orange" underline className="font-bold">
                                        {t("collaborate.button")}
                                    </TextLink>
                                </Link>
                            </div>
                        </>
                    )}
                </Popover.Panel>
            </Transition>
            <div className="sr-only">
                {data.map((section) => (
                    <Fragment key={section.title}>
                        {section.items.map((link, i) => (
                            <a key={i} href={link.href[locale]}>
                                {t(link.title)}
                            </a>
                        ))}
                    </Fragment>
                ))}
            </div>
        </Popover>
    );
});

NavBarSubMenu.displayName = "NavBarSubMenu";

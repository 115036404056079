import { useOnMount } from "@uxf/core/hooks/useOnMount";
import { cx } from "@uxf/core/utils/cx";
import { rem } from "@uxf/styles/units/rem";
import React, { CSSProperties, memo, PropsWithChildren, useRef, useState } from "react";

interface Props {
    animationDuration: number;
    autoStartMode?: "always" | "onOverflow";
    className?: string;
    gap?: number;
    justifyMode?: "gap" | "around";
}

export const InfiniteImageCarousel = memo<PropsWithChildren<Props>>((props) => {
    const gap = props.gap ?? 32;
    const autoStartMode = props.autoStartMode ?? "always";
    const justifyMode = props.justifyMode ?? "gap";

    const ref = useRef<HTMLDivElement>(null);
    const elementOriginalWidth = useRef<number>(0);

    const [isRunning, setIsRunning] = useState(false);

    useOnMount(() => {
        const node = ref.current;
        if (!node) {
            return;
        }
        elementOriginalWidth.current = node.scrollWidth;

        const handleStartAnimation = () => {
            const parentWidth = node.parentElement?.clientWidth ?? 0;

            if (autoStartMode === "onOverflow" && parentWidth >= elementOriginalWidth.current) {
                if (node.firstChild && node.children.length === 2) {
                    node.firstChild.remove();
                }
                node.style.animation = "none";
                setIsRunning(false);
                return;
            }

            if (node.firstChild && node.children.length === 1) {
                node.appendChild(node.firstChild.cloneNode(true));
            }

            node.style.setProperty("--scroll-length", `-${rem((node.scrollWidth + gap) / 2)}`);
            node.style.animation = `scroll ${props.animationDuration}s linear infinite`;
            setIsRunning(true);
        };

        handleStartAnimation();
        window.addEventListener("resize", handleStartAnimation);

        return () => window.removeEventListener("resize", handleStartAnimation);
    });

    return (
        <div
            className={`mx-auto flex max-w-[4000px] items-center justify-center overflow-hidden ${
                props.className ?? ""
            }`}
        >
            <div
                className={cx(
                    "flex items-center space-x-[var(--gap)] overflow-visible",
                    justifyMode === "around" && !isRunning
                        ? "w-full [&_>_div]:min-w-full [&_>_div]:justify-around"
                        : "w-auto",
                )}
                style={
                    {
                        "--gap": `${rem(gap)}`,
                    } as CSSProperties
                }
                ref={ref}
            >
                <div className="flex shrink-0 items-center space-x-[var(--gap)]">{props.children}</div>
            </div>
        </div>
    );
});

InfiniteImageCarousel.displayName = "InfiniteImageCarousel";

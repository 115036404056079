import { Icon } from "@ui/components/icon/icon";
import { TextLink } from "@ui/components/text-link/text-link";
import { cx } from "@uxf/core/utils/cx";
import { useTranslation } from "next-i18next";
import React, { forwardRef } from "react";

interface NavbarButtonProps {
    hasSubMenu?: boolean;
    href?: string;
    onClick?: () => void;
    title: string;
    variant?: "white" | "violet";
}

export const NavbarButton = forwardRef<HTMLAnchorElement, NavbarButtonProps>(
    ({ href, onClick, title, variant = "violet", hasSubMenu }, ref) => {
        const { t } = useTranslation();
        return (
            <TextLink
                className={cx(
                    "ui-typo-body flex items-center font-medium",
                    variant === "white" && "text-white is-hoverable:text-whiteHover",
                )}
                href={href}
                role="listitem"
                variant={variant === "white" ? "custom" : "violet"}
                onClick={onClick}
                ref={ref}
            >
                {t(title)}
                {hasSubMenu && <Icon name="chevronRight" className="ml-2 rotate-90" size={12} />}
            </TextLink>
        );
    },
);

NavbarButton.displayName = "NavbarButton";

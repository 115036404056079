import { COOKIE_CONSENT_VERSION } from "@shared/lib/cookie-consent/cookie-consent.constants";
import { isConsentCookieSet } from "@uxf/analytics/consent";
import { useIsMounted } from "@uxf/core/hooks/useIsMounted";
import { useIsomorphicLayoutEffect } from "@uxf/core/hooks/useIsomorphicLayoutEffect";
import React, { FC, memo, useCallback, useState } from "react";
import { CookieConsentBar } from "./cookie-consent-bar";

const Component: FC = () => {
    const [isOpen, setIsOpen] = useState(false);

    const closeModal = useCallback(() => {
        setIsOpen(false);
    }, []);

    useIsomorphicLayoutEffect(() => {
        const isConsentSet = isConsentCookieSet(null, COOKIE_CONSENT_VERSION);
        if (!isConsentSet) {
            setIsOpen(true);
        }
    }, []);

    return <>{isOpen && <CookieConsentBar onClose={closeModal} />}</>;
};

const Wrapper: FC = (props) => {
    const isMounted = useIsMounted();

    return isMounted ? <Component {...props} /> : null;
};

export const CookieConsent = memo(Wrapper);

CookieConsent.displayName = "CookieConsent";

import { Icon } from "@ui/components/icon/icon";
import { cx } from "@uxf/core/utils/cx";
import useEmblaCarousel from "embla-carousel-react";
import { useTranslation } from "next-i18next";
import React, { useCallback } from "react";

export const useCarousel = () => {
    const { t } = useTranslation();
    const [emblaRef, emblaApi] = useEmblaCarousel({ align: "start", containScroll: "trimSnaps" });

    const scrollNext = useCallback(() => emblaApi?.scrollNext(), [emblaApi]);
    const scrollPrev = useCallback(() => emblaApi?.scrollPrev(), [emblaApi]);

    const buttonClassName =
        "rounded-full border-2 p-4 hover:border-uxfOrange hover:border-opacity-75 hover:text-uxfOrange inline-flex transition-colors";

    const scrollArrows = (
        <div className="z-20 flex">
            <button className={cx(buttonClassName, "mr-4")} onClick={scrollPrev}>
                <span className="sr-only">{t("common.arrowPrevious")}</span>
                <Icon name="arrowLeftCircle" size={20} />
            </button>
            <button className={buttonClassName} onClick={scrollNext}>
                <span className="sr-only">{t("common.arrowNext")}</span>
                <Icon name="arrowRightCircle" size={20} />
            </button>
        </div>
    );

    return [emblaRef, scrollArrows] as const;
};

import { SectionLayout } from "@ui/components/section-layout/section-layout";
import { ClutchTile } from "@ui/components/testimonials/clutch-tile";
import { TestimonialTile, TestimonialTileProps } from "@ui/components/testimonials/testimonial-tile";
import { Show } from "@uxf/core/components/show";
import { cx } from "@uxf/core/utils/cx";
import React, { FC, memo } from "react";

interface Props {
    sectionName?: string;
    sectionTitle?: string;
    items: Omit<TestimonialTileProps, "isSingle">[];
}

const TOP_QUOTE = (
    <svg fill="none" viewBox="0 0 169 120" className="ml-8 h-24 translate-y-6 md:translate-y-10 lg:h-36">
        <path
            d="M36.184 48c-4.252 0-8.274.862-12.062 2.212V48c0-13.237 10.818-24 24.123-24 6.66 0 12.061-5.374 12.061-12S54.916 0 48.245 0C21.642 0 0 21.525 0 48v36c0 19.882 16.2 36 36.184 36 19.984 0 36.183-16.118 36.183-36S56.16 48 36.184 48Zm96.489 0c-4.251 0-8.273.864-12.061 2.205V48c0-13.237 10.818-24 24.123-24 6.66 0 12.061-5.374 12.061-12s-5.401-12-12.061-12C118.132 0 96.49 21.532 96.49 48v36c0 19.882 16.2 36 36.183 36 19.984 0 36.184-16.118 36.184-36s-16.207-36-36.184-36Z"
            fill="#140939"
            fillOpacity={0.1}
        />
    </svg>
);

const BOTTOM_QUOTE = (
    <svg viewBox="0 0 169 120" className="-mt-12 ml-auto mr-8 h-24 md:-mt-20 md:h-32 lg:h-36" fill="none">
        <path
            d="M36.144 0C16.182 0 0 15.787 0 36c0 20.212 16.182 36 36.144 36 4.247 0 8.264-.864 12.048-2.205V72c0 13.237-10.806 24-24.096 24-6.653 0-12.048 5.374-12.048 12s5.395 12 12.048 12c26.573 0 48.192-21.532 48.192-48V36c0-20.212-16.19-36-36.144-36ZM168.67 36c0-19.883-16.182-36-36.144-36-19.961 0-36.143 16.117-36.143 36s16.182 36 36.143 36c4.247 0 8.265-.864 12.048-2.205V72c0 13.237-10.805 24-24.095 24-6.653 0-12.048 5.374-12.048 12s5.395 12 12.048 12c26.573 0 48.191-21.532 48.191-48V36Z"
            fill="#140939"
            fillOpacity={0.1}
        />
    </svg>
);

const Content: FC<{ items: TestimonialTileProps[] }> = ({ items }) => (
    <div
        className={cx(
            "relative z-1 gap-10",
            items.length === 1 ? "sm:px-6 md:px-12 lg:px-24" : "grid md:grid-cols-2 lg:grid-cols-3",
        )}
    >
        {items.map((item, index) => {
            return (
                <div key={index}>
                    <Show when={index === 0 && items.length !== 1}>
                        <ClutchTile />
                    </Show>
                    <TestimonialTile {...item} isSingle={items.length === 1} />
                </div>
            );
        })}
    </div>
);

export const Testimonials = memo<Props>(({ items, sectionName, sectionTitle }) => {
    const showSectionLayout = !!sectionName && !!sectionTitle;
    const isSingle = items.length === 1;

    return (
        <div className={`bg-uxfVioletLightest ${!showSectionLayout && isSingle ? "pb-12 pt-4" : "py-24"}`}>
            {showSectionLayout ? (
                <SectionLayout name={sectionName} title={sectionTitle} className="!mb-0">
                    <Content items={items} />
                    {BOTTOM_QUOTE}
                </SectionLayout>
            ) : (
                <div className="ui-container">
                    {TOP_QUOTE}
                    <Content items={items} />
                    {BOTTOM_QUOTE}
                </div>
            )}
        </div>
    );
});

Testimonials.displayName = "Testimonials";
